.report-page {
    max-width: 800px;
}

.apex-charts text {
    fill: transparent
}

/* css */
html {
    scroll-behavior: smooth;
}

body {
    background: #FFF!important;
}

.fw-50 {
    width: 50px
}

.report-settings {
    height: 900px
}

.fw-550 {
    width: 550px
}

.top-left-content-title {
    string-set: topleft_content_title content();
}

.top-left-content-url {
    string-set: topleft_content_url content();
}

.top-right-content-title {
    string-set: topright_content_title content();
}

.top-right-content-date {
    string-set: topright_content_date content();
}

.toc a[href]::after {
   text-decoration: none;
   color: #495057;
   content: leader(' ') target-counter(attr(href), page);
}

.toc a {
    color: #495057
}

@page {
    size: a4 portrait;
	margin: 100px 50px 50px;
	padding: 0;
    @top-left {
        font-family: "Poppins",sans-serif;
        margin-left: -22px;
        padding-left: 0;
        margin-top: 15px;
        content: string(topleft_content_title) "\a" string(topleft_content_url) ;
        white-space: pre-wrap;
        font-size: 13px;
        color: gray;
    }
    @top-right {
        font-family: "Poppins",sans-serif;
        margin-top: 15px;
        content: string(topright_content_title) "\a" string(topright_content_date) ;
        white-space: pre-wrap;
        font-size: 13px;
        color: gray;
    }
    @bottom-right {
        content: counter(page);
        vertical-align: bottom;
        font-family: Arial, sans-serif;
        font-size: 8pt;
        color: gray;
        margin-bottom: 40px;
    }
}

@page :first {
    border-bottom: none;
    @top-left {
        content: none;
    }
    @top-center {
        content: none;
    }
    @top-right {
        content: none;
    }
    @bottom-right {
        content: none;
    }
    @bottom-left {
        content: none;
    }
    @bottom-center {
        content: none;
    }
    padding: 0;
    margin: 0;
}

.cover-title-details {
    padding: 30px
}

.pagespeed-chart-container {
    width: 500px;
    margin: 0 auto;
    display: block
}

#SvgjsSvg1001 {
    display:none
}