.report-page {
    max-width: 800px;
}

.apex-charts text {
    fill: transparent
}

/* css */
html {
    scroll-behavior: smooth;
}

.fw-50 {
    width: 50px
}

.report-settings {
    height: 900px
}

.fw-550 {
    width: 550px
}

.report-buttons {
    right: 15px!important;
    min-width: 180px;
    box-shadow: 0 0 13px 0 rgba(97, 92, 92, 0.3);
    -webkit-box-shadow: 0 0 13px 0 rgba(97, 92, 92, 0.3);
    -moz-box-shadow: 0 0 13px 0 rgab(97, 92, 92, 0.3);
    z-index: 1;
    padding: 10px 10px 0 0;
    background: #FFF;
}

.report-section-settings {
    max-height: 300px;
    overflow-x: hidden;
    overflow-y: auto;
}

#SvgjsSvg1001 {
    display:none
}

#schemaMarkupPre {
    font-family: $font-family-sans-serif;
    font-size: 13px;
    font-weight: 400;
    white-space: pre-wrap;
    color:$text-muted;
    margin: 0;
}

.tooltip {
    z-index: 99999;
}


#page-topbar {
    .notification-item {
        .avatar-xs {
            flex-basis: 33px;
            flex-grow: 0;
            flex-shrink: 0;
        }
    }
}